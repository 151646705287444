import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'

const NotFoundPage = () => {
  return (
    <Layout>
      <h1>Está pagina no existe.</h1>
      <Link to="/">Inicio</Link>
    </Layout>
  )
}

export default NotFoundPage
